
import { ArrowNarrowRightIcon } from '@heroicons/vue/solid';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
export default {
  name: 'support',
  components: {
    ArrowNarrowRightIcon,
    SystemStatusBadge,
  },
};
